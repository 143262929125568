
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IonApp } from "@ionic/vue";

export default defineComponent({
  name: "Index",
  components: {
    IonApp,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const getDrivingSchoolAction = async (drivingSchoolId: number) => {
      await store.dispatch("public-info/getDrivingSchool", drivingSchoolId);
    };

    onMounted(() => {
      getDrivingSchoolAction(Number(route.params.drivingSchoolId));
    });

    return {};
  },
});
